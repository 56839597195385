
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        





























































.freelancer-starter {
  overflow: hidden;
  color: $white;
  background: linear-gradient(180deg, $c-promo-card 0%, #0a182a 100%);

  &__inner {
    position: relative;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    gap: $spacing * 2;

    @include mq($until: l) {
      grid-template-columns: 1fr;
    }
  }

  &__left {
    z-index: 2;
    background: rgba($white, 6%);
    border-radius: $spacing;
    padding: 50px 70px;
    backdrop-filter: blur(8px);
    box-shadow: 0px 20px 50px 0px rgba($black, 1.5%);

    @include mq($until: l) {
      padding: 30px;
      order: 2;
    }
  }

  &__title {
    margin-top: 0;
  }

  &__text {
    ::v-deep {
      a {
        @extend %link--pink;

        background-position: 100% 100%, 0 100%;
        background-image: linear-gradient($c-pink-medium, $c-pink-medium),
          linear-gradient($white, $white);

        &:hover {
          color: $white;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @include mq($until: l) {
      height: 380px;
      order: 1;
    }

    @include mq($until: s) {
      height: 70vw;
    }
  }

  &__picture {
    position: absolute;
    border-radius: 100%;
    overflow: hidden;

    &-topleft {
      width: 300px;
      height: 300px;
      top: -5%;
      left: 10%;

      @include mq($until: m) {
        width: 40vw;
        height: 40vw;
      }
    }

    &-middleright {
      width: 380px;
      height: 380px;
      right: 0;

      @include mq($until: m) {
        width: 50vw;
        height: 50vw;
      }
    }

    &-bottomleft {
      width: 240px;
      height: 240px;
      left: 0;
      bottom: -5%;

      @include mq($until: m) {
        width: 30vw;
        height: 30vw;
      }
    }

    &__img {
      @include image-fit(cover);
    }
  }

  &__radial {
    position: absolute;
    z-index: 0;
    width: 700px;
    height: 700px;
    left: 0;
    bottom: 200px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      $c-blue-mat 0%,
      rgba(76, 23, 142, 0) 100%
    );
    opacity: 0.6;
    filter: blur(60px);

    @include mq($until: l) {
      bottom: -100px;
    }
  }
}
