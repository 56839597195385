
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        



















































































































.freelancer-offers-pack {
  display: inline-flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  background: linear-gradient(180deg, $c-white-dark 0%, $white 100%);
}

.freelancer-offers-pack__inner {
  overflow: hidden;
  width: 100%;
  margin: 0 $spacing $spacing $spacing;
  color: $white;
  background: linear-gradient(180deg, $c-promo-card 0%, #0a182a 100%);
  backdrop-filter: blur(40px);
  border-radius: 20px;
}

.freelancer-offers-pack__radial {
  position: absolute;
  z-index: -1;
  top: -200px;
  right: 100px;
  width: 700px;
  height: 700px;
  /* stylelint-disable declaration-colon-newline-after, color-function-notation, alpha-value-notation */
  background: radial-gradient(
    50% 50% at 50% 50%,
    $c-blue-mat 0%,
    rgba(76, 23, 142, 0) 100%
  );
  /* stylelint-enable declaration-colon-newline-after, color-function-notation, alpha-value-notation */
  opacity: 0.6;
  filter: blur(60px);
}

.freelancer-offers-pack__speedlines {
  top: 0;
  right: 0;

  @include mq($until: xl) {
    display: none;
  }
}

.freelancer-offers-pack__title {
  margin: 0.4em 0;
}

.freelancer-offers-pack__rgus {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 3em;

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    padding: 0 $spacing;

    &__icon {
      fill: $white;
      margin: 0;
      margin-top: 5rem;

      @include mq($until: m) {
        height: 45px;
      }
    }

    &__title {
      margin: $spacing 0;
    }

    &__text {
      @include mq($until: m) {
        display: none;
      }
    }

    &:last-child {
      @include mq($until: l) {
        width: 100%;
        margin-top: $spacing;
      }
    }
  }
}

.freelancer-offers-pack__pack__logo__img {
  max-height: 45px;
}

.freelancer-offers-pack__pack__button {
  margin-top: $spacing;
  white-space: nowrap;
}

.freelancer-offers-pack__pack__price {
  font-family: $ff-alt;
  font-size: 5em;
  font-weight: 700;
  line-height: 1;

  @include mq($until: xl) {
    font-size: 4em;
  }
}

.freelancer-offers-pack__pack__sale-description {
  padding-top: $spacing;
  padding-bottom: $spacing * 0.5;
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.4rem;
}
