
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        























































































































.vertical__hero {
  @include mq('l') {
    max-height: 69rem;
  }
}

.vertical__centered-block {
  background-color: $c-white-dark;
}

.vertical__domino {
  overflow: hidden !important;
}

.vertical__services {
  > div:not(.services-list__list__item__title) {
    @include mq($until: 's') {
      display: none;
    }
  }

  ::v-deep .services-list__list {
    @include mq(xl) {
      justify-content: flex-end;
      row-gap: unset;
      gap: 6rem;
    }
  }
}

.vertical__speedlines {
  max-width: none;
  background: linear-gradient(to bottom, $c-cultured 50%, $white 50%);

  ::v-deep .banner-speedlines__speedlines {
    z-index: 0;
  }

  ::v-deep .banner-speedlines-inner {
    z-index: 1;
    position: relative;
  }
}

::v-deep .vertical__related.card-small.is-lightest-gray,
::v-deep .vertical__faq.faq-outer.is-lightest-gray {
  background: $white;
}

::v-deep .vertical__faq .faq-item.is-white {
  background: $c-cultured;
}

::v-deep .dominopill-inner {
  position: initial;
}

::v-deep .dominopill__speedlines {
  left: 50%;
}

::v-deep .homepage-myvoo.is-white {
  background: $c-white-dark;
}

::v-deep .homepage-myvoo__speedlines {
  display: none;
}
